<div>
  <div class="grid lg:w-3 m-auto">
    <p-messages class="col-12" [(value)]="msgs"></p-messages>
    <div class="col-12">
      <div class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-envelope"></i>
        </span>
        <input type="text" id="emailText" pInputText placeholder="Email" [(ngModel)]="email">
      </div>
    </div>
    <div class="col-12">
      <div class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-lock"></i>
        </span>
        <input type="password" id="passwordText" pInputText placeholder="Password" [(ngModel)]="password">
      </div>
    </div>
    <div class="col-12">
      <button pButton class="w-full" type="button" label="Login" id="loginButton" (click)="login()"></button>
    </div>
  </div>
</div>