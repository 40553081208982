<p-panel [header]="'Edit question'">
  <div *ngIf="question; else not_found">
    <div class="grid">
      <p-messages [(value)]="msgs" class="col-12 validation-block" [closable]="false"></p-messages>
      <div class="hidden">{{question._id}}</div>
      <div class="lg:col-1 lg:block hidden">
        <label for="content">Question:</label>
      </div>
      <div class="lg:col-11 lg:block col-12">
        <textarea id="content" class="w-full h-6rem lg:h-auto" [rows]="1" [(ngModel)]="question.content"
          pInputTextarea></textarea>
      </div>
      <div class="lg:col-1 lg:block hidden">
        <label>Difficulty:</label>
      </div>
      <div class="lg:col-11 col-12 spinner-diff">
        <p-inputNumber class="w-10rem" [(ngModel)]="question.difficulty" [showButtons]="true" [required]="true"
          [min]="1" [max]="10">
        </p-inputNumber>
      </div>
      <div class="lg:col-1 lg:block hidden">
        <label>Tags:</label>
      </div>
      <div class="lg:col-11 col-12">
        <p-autoComplete [(ngModel)]="question.tags" [suggestions]="possibleTags" (completeMethod)="searchTags($event)"
          [multiple]="true"></p-autoComplete>
      </div>
      <div class="lg:col-1 col-2">
        <label for="draft">Draft:</label>
      </div>
      <div class="lg:col-11 col-10 draft-check">
        <p-checkbox name="draft" (onChange)="setStatus(question, $event)" binary="true"
          [ngModel]="question.status === draftStatus"></p-checkbox>
      </div>
      <div class="lg:col-1 col-3 text-300" *ngIf="!newQuestion">
        <label>Modified:</label>
      </div>
      <div class="lg:col-11 col-9 text-300" title="Created: {{Utils.formatDate(question.createdAt)}}"
        *ngIf="!newQuestion">
        {{Utils.formatDate(question.updatedAt)}}
        {{Utils.updatedByFormatted(question)}}
      </div>
      <div class="lg:col-1 col-3 text-300" *ngIf="!newQuestion">
        <label>Answered:</label>
      </div>
      <div class="lg:col-11 col-9 text-300" *ngIf="!newQuestion">
        <span title="Correct answers">{{Utils.getAnswersRight(question)}}</span>/<span
          title="Total answers">{{Utils.getAnswersCountForQuestion(question)}}</span>
      </div>
      <div class="lg:col-1 col-3 text-300" *ngIf="!newQuestion">
        <label>Rotation:</label>
      </div>
      <div class="lg:col-11 col-9 text-300" *ngIf="!newQuestion">
        <span title="Answered across all the questions">{{Utils.formatRotated(question, questions)}}%</span>
      </div>

      <div class="answers-block">
        <div *ngFor="let answer of question.answers">
          <div class="p-grid-row p-inputgroup col-12">
            <input class="col-12" type="text" pInputText [(ngModel)]="answer.content"
              (ngModelChange)="answerChange(question, answer, $event)" placeholder="Option">
            <span class="p-inputgroup-addon">
              <p-checkbox [(ngModel)]="answer.right" binary="true"
                (onChange)="answerRightnessChange(question, answer, $event)"></p-checkbox>
            </span>
          </div>
          <div class="p-grid-row p-inputgroup col-12 stat"
            *ngIf="Utils.getAnswerPercentage(answer, question.answers) as percentage" title="Answer chosen times">
            {{answer.chosen}}
            ({{percentage}})</div>
        </div>
      </div>
    </div>
    <div class="grid">
      <div class="col-12 flex flex-wrap lg:justify-content-end">
        <button type="button" class="flex-initial w-full lg:w-auto mb-2 lg:ml-2 lg:w-10rem" pButton [disabled]="!!newQuestion" (click)="delete()" label="Delete"
          icon="pi pi-trash">
        </button>
        <button type="button" class="flex-initial w-full lg:w-auto mb-2 lg:ml-2 lg:w-10rem" pButton (click)="preview()" label="{{previewLabel}}"
          icon="pi pi-info"></button>
        <button type="button" class="flex-initial w-full lg:w-auto mb-2 lg:ml-2 lg:w-10rem" pButton (click)="save()" label="Save"></button>
        <button type="button" class="flex-initial w-full lg:w-auto mb-2 lg:ml-2 lg:w-10rem" pButton (click)="cancel()" label="Cancel"></button>
      </div>
    </div>
  </div>
  <ng-template #not_found>The question is not found</ng-template>
</p-panel>