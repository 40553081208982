import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IAppConfig } from '../app.module';

@Injectable()
export class AppConfigService {
  private appConfig;

  private configUrl = '/frontend.config.json';
  constructor(private http: HttpClient) { }

  loadAppConfig() {
    return this.http.get(this.configUrl)
      .toPromise()
      .then(data => {
        this.appConfig = data;
        console.log('Configuration is loaded: ', data);
      })
      .catch(err => {
        this.appConfig = {};
        console.warn(`Configuration can't be loaded from '${this.configUrl}'. Default is applied. Error: ${err.message}.`);
      });
  }

  getConfig(): IAppConfig {
    return this.appConfig;
  }
}
