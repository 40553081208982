import { Injectable } from '@angular/core';

const TOKEN = 'token';

@Injectable()
export class TokenService {
  constructor() {}

  public removeToken(): void {
    window.localStorage.removeItem(TOKEN);
  }

  public setToken(token: any): void {
    window.localStorage.setItem(TOKEN, token);
  }

  public getToken(): string {
    return window.localStorage.getItem(TOKEN);
  }

  public getTokenHeaderValue(): string {
    return 'Bearer ' + this.getToken();
  }

  public isAuthorized(): boolean {
    return !!this.getToken();
  }
}
