import { Injectable } from "@angular/core";
@Injectable()
export class NavigationService {
    private questionsRowIndex = 0;
    private questionFilter;

    public saveQuestionsRowIndex(page: number) {
        this.questionsRowIndex = page;
    }

    public getQuestionsRowIndex(): number {
        return this.questionsRowIndex;
    }

    public saveQuestionFilter(filter) {
        this.questionFilter = filter;
    }

    public getQuestionFilter(): string {
        return this.questionFilter;
    }
}
