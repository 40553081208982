/**
 * Jannah Quiz API
 * JannahQuiz API server
 *
 * OpenAPI spec version: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Answer } from './answer';
import { EditorShort } from './editorShort';


export interface Question { 
    _id?: string;
    content: string;
    answers: Array<Answer>;
    tags?: Array<string>;
    /**
     * Question status
     */
    status: Question.StatusEnum;
    difficulty: number;
    createdAt?: string;
    updatedAt?: string;
    updatedBy?: Array<EditorShort>;
}
export namespace Question {
    export type StatusEnum = 'draft' | 'approved';
    export const StatusEnum = {
        Draft: 'draft' as StatusEnum,
        Approved: 'approved' as StatusEnum
    };
}
