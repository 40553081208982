<ng-container *ngIf="(players$ | async) as players">
  <p-table [columns]="cols" [paginator]="true" [rows]="12" [value]="players" sortField="points_weekly" sortOrder="-1"
    responsiveLayout="scroll">
    <ng-template pTemplate="caption">
      Players
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let col of cols" [pSortableColumn]="col.field" [style.width]="col.width"
          [class.hideMobile]="col.hideMobile" [class.hideTablet]="col.hideTablet">
          {{col.header}}
          <p-sortIcon [field]="col.field"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-player let-columns="cols">
      <tr>
        <td *ngFor="let col of cols" [style.textAlign]="col.align" [class.hideMobile]="col.hideMobile"
          [class.hideTablet]="col.hideTablet">
          {{ getCellValue(player, col.field) }}
        </td>
      </tr>
    </ng-template>
  </p-table>
</ng-container>