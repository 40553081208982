import { Answer, Question, EditorShort } from "../client";

export class Utils {
  static allowedTags = ['Sira', 'Propheten', 'Koran', 'Fikh', 'Aaquidah', 'Hadith'];

  static monthNames = [
    'January', 'February', 'March',
    'April', 'May', 'June', 'July',
    'August', 'September', 'October',
    'November', 'December'
  ];

  public static formatTime(date: Date): string {
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: '2-digit',
      year: "2-digit",
      hour: '2-digit',
      minute: '2-digit'
    };
    return date.toLocaleDateString('en-DE', options);
  }

  public static formatDate(date: string) {
    if (!date) {
      return '';
    }
    return Utils.formatTime(new Date(date));
  }

  public static updatedByFormatted(question: Question) {
    return (question && question.updatedBy && question.updatedBy.length > 0)
      ? ('by ' + question.updatedBy.map(item => item.name).join(','))
      : '';
  }

  public static getAnswersRight(question: Question): number {
    for (const answer of question.answers) {
      if (answer.right) {
        return answer.chosen;
      }
    }
    return 0;
  }

  public static getAnswersCountForQuestion(question: Question): number {
    const res = question.answers.reduce((prevVal, cur) => {
      return (prevVal + (cur.chosen || 0));
    }, 0);
    return res;
  }

  public static formatRotated(question: Question, questions: Question[]): string {
    return this.getRelativeAnswersCountForQuestion(question, questions).toFixed(6);
  }

  public static getRelativeAnswersCountForQuestion(question: Question, questions: Question[]): number {
    const totalAnswers = this.getTotalAnswersCount(questions);
    if (totalAnswers <= 0) {
      return 0
    }
    return (this.getAnswersCountForQuestion(question) / totalAnswers);
  }

  public static getTotalAnswersCount(questions: Question[]): number {
    if (!questions) {
      return 0;
    }

    return questions.reduce((prevValue, cur) => prevValue + this.getAnswersCountForQuestion(cur), 0);
  }

  public static getAnswerPercentage(answer: Answer, answers: Answer[]): string {
    const totalChosen = answers
      .map(ans => ans.chosen || 0)
      .reduce((accumulator, currentValue) => accumulator + currentValue);

    if (totalChosen === 0) {
      return null;
    }

    return (Math.floor((answer.chosen || 0) / totalChosen * 100)) + '%';
  }

  public static formatAnswered(question: Question) {
    return `${Utils.getAnswersRight(question)}/${Utils.getAnswersCountForQuestion(question)}`;
  }

  public static formatEditor(editors: EditorShort[]): string {
    if (!editors || editors.length === 0) {
      return '-';
    }

    return editors.map(editor => editor.name).join(', ');
  }

  public static formatDifficulty(diff: number) {
    return diff.toFixed(2);
  }

}
