import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { EditorService } from '../client/index';
import { AppConfigService } from '../services/app.config.service';
import { Utils } from '../questions/utils';
import { API_VERSION } from '../auth/version';

@Component({
  selector: 'app-editors',
  templateUrl: './editors.component.html',
  styleUrls: ['./editors.component.scss']
})
export class EditorsComponent implements OnInit {
  public editors$: Observable<{ name: string, email: string, lastVisitAt: string }[]>;

  constructor(
    private appConfigService: AppConfigService,
    private editorService: EditorService
  ) { }

  ngOnInit() {
    this.editors$ = this.editorService.getEditors(API_VERSION)
      .pipe(
        map(editors => editors.map(editor => ({
          name: editor.name,
          email: editor.email,
          lastVisitAt: Utils.formatDate(editor.lastVisitAt)
        })))
      );
  }
}
