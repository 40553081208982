import { Component, OnInit } from '@angular/core';
import { TokenService } from './auth/token.service';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(private tokenService: TokenService, private router: Router) { }

  public title = 'Jannah Quiz';
  public menuItems: MenuItem[];


  ngOnInit() {
    this.menuItems = [
      {
        label: 'Dashboard',
        routerLink: ['dashboard'],
      },
      {
        label: 'Questions',
        routerLink: ['questions']
      },
      {
        label: 'Players',
        routerLink: ['players']
      },
      {
        label: 'Editors',
        routerLink: ['editors']
      },
      {
        label: 'Logout',
        command: (event) => {
          this.logout(event);
        }
      }
    ];
  }

  public isAuthorized() {
    return this.tokenService.isAuthorized();
  }

  public logout(event: Event) {
    this.tokenService.removeToken();
    this.router.navigate(['login']);
  }
}
