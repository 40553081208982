<div>
  <div *ngIf="!isAuthorized()">
    <router-outlet></router-outlet>
  </div>
  <div *ngIf="isAuthorized()" class="grid">
    <div class="lg:col-2 col-12"><a [routerLink]="['/']" class="hidden lg:inline"><img src="assets/logo.png"
          alt="Jannah Quiz" title="Jannah Quiz" class="w-full"></a>
      <p-menu [model]="menuItems" multiple="false" class="w-full"></p-menu>
    </div>
    <div class="lg:col-10 col-12">
      <router-outlet></router-outlet>
    </div>
    <p-toast life="2000"></p-toast>
    <p-confirmDialog header="Confirmation" icon="pi pi-question-circle"></p-confirmDialog>
  </div>
</div>