import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { TokenService } from './token.service';

@Injectable()
export class CheckUnAuthGuard implements CanActivate {
  constructor(private tokenService: TokenService, private router: Router) { }

  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const authorized = !!this.tokenService.getToken();

    if (authorized) {
      this.router.navigate(['']);
    }

    return !authorized;
  }
}
