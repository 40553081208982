import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, SortEvent } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { PublishStatistics, Question } from '../client/index';
import '../rxjs-operators';
import { LogService } from '../services/log.service';
import { NavigationService } from '../services/navigation.service';
import { QuestionManager } from '../services/question.manager';
import { Utils } from './utils';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss'],
})
export class QuestionsComponent implements AfterViewInit {
  @ViewChild(Table, { static: true }) table: Table;

  public questions: Question[];
  public questionsShown: Question[];
  public cols: any;
  public filterText: string;
  public firstIndex: number;

  constructor(
    private questionManager: QuestionManager,
    private logService: LogService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private router: Router,
    private navigationService: NavigationService,
  ) {
    this.cols = [
      { field: 'num', header: '#', width: '72px', align: 'right' },
      { field: 'content', header: 'Question', width: '100%%', align: 'left' },
      { field: 'difficulty', header: 'Diff', width: '90px', align: 'right', hideMobile: true },
      { field: 'answered', header: 'Answers', width: '128px', align: 'right', hideMobile: true },
      { field: 'status', header: 'Status', width: '112px', align: 'center', hideMobile: true },
      { field: 'editor', header: 'Editor', width: '160px', align: 'center', hideMobile: true, hideTablet: true },
      { field: 'edited', header: 'Date', width: '160px', align: 'center', hideMobile: true, hideTablet: true },
    ];
  }

  customSort(event: SortEvent) {
    event.data.sort((data1: Question, data2: Question) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (event.field === 'num') {
        result = value1 - value2;
      } else if (event.field === 'difficulty') {
        result = value1 - value2;
      } else if (event.field === 'edited') {
        result = (new Date(data1.updatedAt) as any) - (new Date(data2.updatedAt) as any);
      } else if (event.field === 'answered') {
        result = Utils.getAnswersCountForQuestion(data1) - Utils.getAnswersCountForQuestion(data2);
      } else if (event.field === 'editor') {
        const name1 = data1.updatedBy.length > 0 ? data1.updatedBy[0].name : '';
        const name2 = data2.updatedBy.length > 0 ? data2.updatedBy[0].name : '';
        result = name1.localeCompare(name2);
      } else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }

      return (event.order * result);
    });
  }

  getCellValue(questions: Question[], question: Question, fieldName: string): string {
    let formatted: string;
    switch (fieldName) {
      case 'difficulty':
        formatted = Utils.formatDifficulty(question.difficulty);
        break;
      case 'answered':
        formatted = Utils.formatAnswered(question);
        break;
      case 'rotated':
        formatted = Utils.formatRotated(question, questions);
        break;
      case 'answered':
        formatted = Utils.formatAnswered(question);
        break;
      case 'editor':
        formatted = Utils.formatEditor(question.updatedBy);
        break;
      case 'edited':
        formatted = Utils.formatDate(question.updatedAt);
        break;
      default:
        formatted = question[fieldName];
        break;
    }
    return formatted;
  }

  ngAfterViewInit() {
    this.questionManager.fetchQuestions()
      .subscribe(items => {
        this.questions = items;

        // Need to be applied, if navigated back from the question page (full reinitialization takes place)
        this.firstIndex = this.navigationService.getQuestionsRowIndex();
        this.filterText = this.navigationService.getQuestionFilter();

        this.applyFilter();
      });
  }

  onPage({ first }) {
    this.navigationService.saveQuestionsRowIndex(first);
  }

  public getTotalAnswersCount(questions: Question[]): number {
    if (!questions) {
      return 0;
    }

    return questions.reduce((prevValue, cur) => prevValue + Utils.getAnswersCountForQuestion(cur), 0);
  }

  /**
   * Dialog 'Add question'
   */
  public showDialogToAdd() {
    this.router.navigate(['question', 'new']);
  }

  /**
   * Dialog 'Edit question'
   */
  public showDialogToEdit(event) {
    const targetQuestion = event.data as Question;
    this.router.navigate(['question', targetQuestion._id]);
  }

  /**
   * Publish new version
   */
  public publishVersion() {
    this.confirmationService.confirm({
      message: 'Do you really want to publish the approved questions to all the players?',
      accept: () => {
        this.questionManager.publishNewVersion()
          .subscribe((result: PublishStatistics) => {
            this.logService.info(`New version published ${JSON.stringify(result)}`);
            this.messageService.add({
              severity: 'success',
              summary: `Published`,
              detail: `New version #${result.version} successfully published with ${result.questions} question(s)`
            });
          }, (err) => {
            this.logService.error('Failed to publish new version', err);
          });
      }
    });
  }

  public onFilter() {
    this.navigationService.saveQuestionFilter(this.filterText);
    this.applyFilter();
  }

  public applyFilter(): void {
    if (!this.filterText) {
      this.questionsShown = [...this.questions];
    } else {
      const searchString = this.filterText.toLowerCase();
      const searchNum = parseInt(this.filterText, 10);

      this.questionsShown = this.questions.filter(item => {
        if (item.content.toLowerCase().indexOf(searchString) !== -1) {
          return true;
        }

        if (!isNaN(searchNum) && item['num'] === searchNum) {
          return true;
        }

        if (item.tags.find(tag => tag.localeCompare(searchString, undefined, { sensitivity: 'base' }) === 0)) {
          return true;
        }

        if (item.status.localeCompare(searchString, undefined, { sensitivity: 'base' }) === 0) {
          return true;
        }

        return false;
      });
    }
  }
}
