import { Component, OnInit } from "@angular/core";
import { DashboardService, Question } from "../client";
import { Utils } from "../questions/utils";
import { QuestionManager } from "../services/question.manager";
import { API_VERSION } from "../auth/version";

export interface DifficultyStatItem {
  diff: number;
  count: number;
}

export interface TagStatItem {
  tag: string;
  count: number;
}

export interface RadarData {
  labels: string[];
  datasets: {
    data: number[];
    backgroundColor: string[];
  }[];
}

type Period = "daily" | "monthly" | "yearly";
@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  public difficultyStat: RadarData;
  public tagStat: RadarData;
  public popularityStat: { labels: string[]; datasets: any[] };

  public dailyRegStat: { labels: string[]; datasets: any[] };
  public monthlyRegStat: { labels: string[]; datasets: any[] };
  public yearlyRegStat: { labels: string[]; datasets: any[] };

  public dailyQuestionStat: { labels: string[]; datasets: any[] };
  public monthlyQuestionStat: { labels: string[]; datasets: any[] };
  public yearlyQuestionStat: { labels: string[]; datasets: any[] };

  public dailyPlayersStat: { labels: string[]; datasets: any[] };
  public monthlyPlayersStat: { labels: string[]; datasets: any[] };
  public yearlyPlayersStat: { labels: string[]; datasets: any[] };

  public statTypes = [
    { label: "Daily", value: "daily" },
    { label: "Monthly", value: "monthly" },
    { label: "Yearly", value: "yearly" },
  ];

  public singleStatType = [{ label: "Overall", value: "overall" }];

  public currentRegStatType: Period = "daily";
  public currentQuestionStatType: Period = "daily";
  public currentPlayersStatType: Period = "daily";

  public regChartOptions = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            stepSize: 10,
          },
          stacked: true,
        },
      ],
    },
  };

  public questionChartOptions = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            stepSize: 1000,
          },
        },
      ],
    },
  };

  public playersChartOptions = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            stepSize: 10,
          },
        },
      ],
    },
  };

  constructor(
    private questionManager: QuestionManager,
    private dashboardService: DashboardService
  ) {}

  ngOnInit() {
    this.questionManager.fetchQuestions().subscribe((data) => {
      this.initQuestions(data);
    });

    this.loadRegistrations("daily");
    this.loadQuestionsAnswered("daily");
    this.loadActivePlayers("daily");
  }

  private initQuestions(data: Question[]) {
    const rawDiffStat = this.getDifficultyStatistics(data);
    this.difficultyStat = this.adaptDifficultyStatistics(rawDiffStat);

    const rawTagStat = this.getTagStatistics(data);
    this.tagStat = this.adaptTagStatistics(rawTagStat);

    const rawPopularityStat = this.getPopularityStat(data);
    this.popularityStat = this.adaptPopularityStat(rawPopularityStat);
  }

  private getPopularityStat(data: Question[]): Map<number, number> {
    const stat = new Map<number, number>();
    for (const question of data) {
      if (question.status !== Question.StatusEnum.Approved) {
        continue;
      }
      stat.set(question["num"], Utils.getAnswersCountForQuestion(question));
    }

    return stat;
  }

  private adaptPopularityStat(data: Map<number, number>): {
    labels: string[];
    datasets: any[];
  } {
    // Sort by value
    const dataSorted = new Map([...data.entries()].sort((a, b) => a[1] - b[1]));

    const labels: string[] = [];
    const stat: number[] = [];
    for (const key of dataSorted.keys()) {
      labels.push("#" + key);
      stat.push(dataSorted.get(key));
    }

    return {
      labels: labels,
      datasets: [
        {
          label: "Number of answers",
          backgroundColor: "#9CCC65",
          borderColor: "#7CB342",
          data: stat,
        },
      ],
    };
  }

  private getTagStatistics(data: Question[]): Map<string, number> {
    const tagMap: Map<string, number> = new Map();

    // Add all the tags first
    for (const tag of Utils.allowedTags) {
      tagMap.set(tag, 0);
    }

    // Add statistics based on tags
    for (const question of data) {
      for (const tag of question.tags) {
        let existingCounter = tagMap.get(tag);
        if (existingCounter) {
          existingCounter++;
          tagMap.set(tag, existingCounter);
        } else {
          tagMap.set(tag, 1);
        }
      }
    }

    return tagMap;
  }

  private adaptTagStatistics(diffStat: Map<string, number>): RadarData {
    const res: RadarData = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [
            "#e6194b",
            "#3cb44b",
            "#ffe119",
            "#4363d8",
            "#f58231",
            "#911eb4",
            "#46f0f0",
            "#f032e6",
            "#bcf60c",
            "#fabebe",
            "#008080",
            "#e6beff",
            "#9a6324",
            "#fffac8",
            "#800000",
            "#aaffc3",
            "#808000",
            "#ffd8b1",
            "#000075",
            "#808080",
          ],
        },
      ],
    };

    for (const item of diffStat) {
      res.labels.push(item[0]);
      res.datasets[0].data.push(item[1]);
    }

    return res;
  }

  private getDifficultyStatistics(data: Question[]): number[] {
    const stat: number[] = [];
    for (const question of data) {
      if (question.status !== Question.StatusEnum.Approved) {
        continue;
      }
      const diffRound = Math.round(question.difficulty);
      if (stat[diffRound]) {
        stat[diffRound]++;
      } else {
        stat[diffRound] = 1;
      }
    }
    return stat;
  }

  private adaptDifficultyStatistics(rawStats: number[]): RadarData {
    const res: RadarData = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [
            "#0b6623",
            "#9dc183",
            "#708238",
            "#c7ea46",
            "#3f704d",
            "#00a86b",
            "#4f7942",
            "#29ab87",
            "#01796f",
            "#00a572",
            "#4cbb17",
          ],
        },
      ],
    };
    for (let i = 1; i < rawStats.length; i++) {
      res.labels.push(`${i}`);
      res.datasets[0].data.push(Math.round(rawStats[i]));
    }

    return res;
  }

  loadQuestionsAnswered(period: Period): void {
    this.dashboardService
      .getQuestionsAnswered(API_VERSION, period)
      .subscribe((data) => {
        switch (period) {
          case "daily":
            this.dailyQuestionStat = {
              labels: data.labels,
              datasets: [
                {
                  label: "Questions answered",
                  backgroundColor: "#9CCC65",
                  borderColor: "#7CB342",
                  data: data.stat,
                },
              ],
            };
            break;
          case "monthly":
            this.monthlyQuestionStat = {
              labels: data.labels,
              datasets: [
                {
                  label: "Questions answered",
                  backgroundColor: "#9CCC65",
                  borderColor: "#7CB342",
                  data: data.stat,
                },
              ],
            };
            break;
          case "yearly":
            this.yearlyQuestionStat = {
              labels: data.labels,
              datasets: [
                {
                  label: "Questions answered",
                  backgroundColor: "#9CCC65",
                  borderColor: "#7CB342",
                  data: data.stat,
                },
              ],
            };
            break;
        }
      });
  }
  loadActivePlayers(period: Period) {
    this.dashboardService
      .getActivePlayers(API_VERSION, period)
      .subscribe((data) => {
        switch (period) {
          case "daily":
            this.dailyPlayersStat = {
              labels: data.labels,
              datasets: [
                {
                  label: "Active Players",
                  backgroundColor: "#9CCC65",
                  borderColor: "#7CB342",
                  data: data.stat,
                },
              ],
            };
            break;
          case "monthly":
            this.monthlyPlayersStat = {
              labels: data.labels,
              datasets: [
                {
                  label: "Active Players",
                  backgroundColor: "#9CCC65",
                  borderColor: "#7CB342",
                  data: data.stat,
                },
              ],
            };
            break;
          case "yearly":
            this.yearlyPlayersStat = {
              labels: data.labels,
              datasets: [
                {
                  label: "Active Players",
                  backgroundColor: "#9CCC65",
                  borderColor: "#7CB342",
                  data: data.stat,
                },
              ],
            };
            break;
        }
      });
  }
  loadRegistrations(period: Period) {
    this.dashboardService
      .getRegistrations(API_VERSION, period)
      .subscribe((data: { confirmed; nonconfirmed }) => {
        switch (period) {
          case "daily":
            this.dailyRegStat = {
              labels: data.confirmed.labels,
              datasets: [
                {
                  label: "Not confirmed",
                  backgroundColor: "#FFCE56",
                  borderColor: "#FFCE56",
                  data: data.nonconfirmed.stat,
                  stack: "main",
                },
                {
                  label: "Confirmed (name specified)",
                  backgroundColor: "#9CCC65",
                  borderColor: "#9CCC65",
                  data: data.confirmed.stat,
                  stack: "main",
                },
              ],
            };
            break;
          case "monthly":
            this.monthlyRegStat = {
              labels: data.confirmed.labels,
              datasets: [
                {
                  label: "Not confirmed",
                  backgroundColor: "#FFCE56",
                  borderColor: "#FFCE56",
                  data: data.nonconfirmed.stat,
                  stack: "main",
                },
                {
                  label: "Confirmed (name specified)",
                  backgroundColor: "#9CCC65",
                  borderColor: "#7CB342",
                  data: data.confirmed.stat,
                  stack: "main",
                },
              ],
            };
            break;
          case "yearly":
            this.yearlyRegStat = {
              labels: data.confirmed.labels,
              datasets: [
                {
                  label: "Not confirmed",
                  backgroundColor: "#FFCE56",
                  borderColor: "#FFCE56",
                  data: data.nonconfirmed.stat,
                  stack: "main",
                },
                {
                  label: "Confirmed (name specified)",
                  backgroundColor: "#9CCC65",
                  borderColor: "#7CB342",
                  data: data.confirmed.stat,
                  stack: "main",
                },
              ],
            };
            break;
        }
      });
  }
}
