import { Injectable } from "@angular/core";
@Injectable()
export class LogService {
  constructor() {

  }

  public error(message: string, error: Error) {
    console.error(message, error);
  }

  public warning(message: string, error: Error) {
    console.warn(message, error);
  }

  public info(message: string) {
    console.log(message);
  }
}
