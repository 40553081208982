import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Observable } from 'rxjs/Observable';
import { Answer, Question } from '../client';
import { Utils } from '../questions/utils';
import { LogService } from '../services/log.service';
import { QuestionManager } from '../services/question.manager';
import { RouterExtService } from '../services/router-ext.service';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss']
})
export class QuestionComponent implements OnInit {
  public question: Question;
  public questions: Question[];
  public Utils;
  public msgs = [];
  private defaultPreviewLabel = 'Preview';
  public previewLabel = this.defaultPreviewLabel;
  public displayDialog: boolean;
  public newQuestion: boolean;
  public possibleTags: string[];
  public draftStatus = Question.StatusEnum.Draft;

  constructor(
    private activatedRoute: ActivatedRoute,
    private questionManager: QuestionManager,
    private logService: LogService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private messageService: MessageService,
    private routerExtService: RouterExtService
  ) { }

  ngOnInit() {
    this.Utils = Utils;

    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      const questionsId = params.get('id');

      this.questionManager.fetchQuestions()
        .subscribe(items => {
          this.questions = items;
          if (questionsId === 'new') {
            this.question = this.getDefaultQuestion();
            this.newQuestion = true;
          } else {
            this.question = this.questionManager.getQuestionById(questionsId);
          }

          this.prepareEmptySlots(this.question, undefined);
        });
    });

    this.msgs = [];
  }

  private getDefaultQuestion(): Question {
    return { status: Question.StatusEnum.Draft, content: '', difficulty: 1, answers: [] };
  }

  private validateQuestion() {
    this.msgs = [];
    if (!this.question.content) {
      this.msgs.push({ severity: 'warn', summary: 'No question entered' });
    }

    if (!this.question.answers.find(answer => !!answer.content && answer.right)) {
      this.msgs.push({ severity: 'warn', summary: 'No correct answer selected' });
    }

    if (this.question.answers.filter(answer => !!answer.content).length < 4) {
      this.msgs.push({ severity: 'warn', summary: 'At least 4 answers required' });
    }

    return this.msgs.length === 0;
  }

  /**
   * Save question
   */
  public save() {
    if (!this.validateQuestion()) {
      return;
    }
    const cleanQuestion = this.cleanUpQuestion();

    const result: Observable<Question> = this.newQuestion
      ? this.questionManager.addQuestion(cleanQuestion)
      : this.questionManager.updateQuestion(cleanQuestion);

    result.subscribe(
      (_question: Question) => {
        this.questions = [...this.questionManager.getQuestions()];

        this.messageService.add({
          severity: 'success',
          summary: `Saved`,
          detail: `Question successfully saved`
        });

        this.router.navigate(['questions']);
      },
      (err) => this.logService.error('Save failed', err)
    );
  }

  /**
   * Delete question
   */
  public delete() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the question?',
      accept: () => {
        this.questionManager.deleteQuestion(this.question._id)
          .subscribe(
            () => {
              this.questions = [...this.questionManager.getQuestions()];
              this.router.navigate(['questions']);
            },
            (err) => this.logService.error('Delete failed', err)
          );
      }
    });
  }

  /**
   * Clean question from artifacts
   */
  private cleanUpQuestion(): Question {
    // filter out empty answers
    const questionClean = {
      ...this.question
    };

    questionClean.answers = questionClean.answers.filter(answer => !!answer.content);

    // clean 'right' flag from every answer
    for (const answer of questionClean.answers) {
      if (!answer.right) {
        delete answer.right;
      }
    }

    delete questionClean.updatedBy;
    delete questionClean.updatedAt;

    return questionClean;
  }

  /**
   * Cancel editing
   */
  public cancel() {
    const prevUrl = this.routerExtService.getPreviousUrl();
    if (prevUrl) {
      this.router.navigateByUrl(prevUrl);
    } else {
      this.router.navigate(['questions']);
    }
  }

  /**
   * Preview the question
   */
  public preview() {
    if (!this.validateQuestion()) {
      return;
    }

    const cleanQuestion = this.cleanUpQuestion();

    this.previewLabel = this.previewLabel + '...';

    this.questionManager.markForPreview(cleanQuestion)
      .subscribe(
        () => { },
        (err) => this.logService.error('Failed to preview', err),
        () => {
          setTimeout(() => this.previewLabel = this.defaultPreviewLabel, 300);
        });
  }

  private prepareEmptySlots(question: Question, newText: string) {
    const emptySlotesIndexes = this.getEmptySlotIndexes(question);
    if (emptySlotesIndexes.length === 0) {
      if (!question.answers) {
        question.answers = [];
      }
      question.answers.push(this.getDefaultAnswer());
    }

    if (!newText && emptySlotesIndexes.length > 1) {
      const lastEmptySlotIndex = emptySlotesIndexes[emptySlotesIndexes.length - 1];
      question.answers.splice(lastEmptySlotIndex, 1);
    }
  }

  private getEmptySlotIndexes(question: Question): number[] {
    const emptySlotsIndexes: number[] = [];
    if (!question) {
      throw new Error('no question provided');
    }
    if (!question.answers) {
      return [];
    }

    for (let i = 0; i < question.answers.length; i++) {
      if (!question.answers[i].content) {
        emptySlotsIndexes.push(i);
      }
    }
    return emptySlotsIndexes;
  }

  private getDefaultAnswer(): Answer {
    return { content: '' };
  }

  public answerChange(question: Question, answer: Answer, newText: string) {
    this.prepareEmptySlots(question, newText);
  }

  public answerRightnessChange(question: Question, answer: Answer, newValue: boolean) {
    if (newValue) {
      this.resetRestAnswers(question, answer);
    }
  }

  private resetRestAnswers(question: Question, currentAnswer: Answer): any {
    for (const answer of question.answers) {
      if (answer !== currentAnswer) {
        answer.right = false;
      }
    }
  }

  public setStatus(question: Question, event: { checked: boolean, originalEvent: PointerEvent }) {
    question.status = event.checked ? Question.StatusEnum.Draft : Question.StatusEnum.Approved;
  }

  public searchTags(sample: { query: string }) {
    const queryAdapted = sample.query.toLowerCase();
    this.possibleTags = Utils.allowedTags.filter(tag => tag.toLowerCase().startsWith(queryAdapted));
  }
}
