/**
 * Jannah Quiz API
 * JannahQuiz API server
 *
 * OpenAPI spec version: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { Difficulty } from '../model/difficulty';
import { PublishStatistics } from '../model/publishStatistics';
import { Question } from '../model/question';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class QuestionService {

    protected basePath = 'http://localhost:8080';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Add a new question
     * 
     * @param apiVersion API version
     * @param question Question to be asked
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addQuestion(apiVersion: string, question: Question, observe?: 'body', reportProgress?: boolean): Observable<Question>;
    public addQuestion(apiVersion: string, question: Question, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Question>>;
    public addQuestion(apiVersion: string, question: Question, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Question>>;
    public addQuestion(apiVersion: string, question: Question, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling addQuestion.');
        }

        if (question === null || question === undefined) {
            throw new Error('Required parameter question was null or undefined when calling addQuestion.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Question>(`${this.basePath}/${encodeURIComponent(String(apiVersion))}/editor/question/`,
            question,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes a question
     * 
     * @param apiVersion API version
     * @param questionId Question ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteQuestion(apiVersion: string, questionId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteQuestion(apiVersion: string, questionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteQuestion(apiVersion: string, questionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteQuestion(apiVersion: string, questionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling deleteQuestion.');
        }

        if (questionId === null || questionId === undefined) {
            throw new Error('Required parameter questionId was null or undefined when calling deleteQuestion.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/${encodeURIComponent(String(apiVersion))}/editor/question/${encodeURIComponent(String(questionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all approved questions
     * 
     * @param apiVersion API version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPublishedQuestions(apiVersion: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getPublishedQuestions(apiVersion: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getPublishedQuestions(apiVersion: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getPublishedQuestions(apiVersion: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling getPublishedQuestions.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/${encodeURIComponent(String(apiVersion))}/player/question/published`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all published questions
     * 
     * @param apiVersion API version
     * @param version Version of the file
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPublishedQuestionsVersion(apiVersion: string, version: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getPublishedQuestionsVersion(apiVersion: string, version: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getPublishedQuestionsVersion(apiVersion: string, version: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getPublishedQuestionsVersion(apiVersion: string, version: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling getPublishedQuestionsVersion.');
        }

        if (version === null || version === undefined) {
            throw new Error('Required parameter version was null or undefined when calling getPublishedQuestionsVersion.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/${encodeURIComponent(String(apiVersion))}/player/question/published/${encodeURIComponent(String(version))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get question by Id
     * 
     * @param apiVersion API version
     * @param questionId Question Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getQuestion(apiVersion: string, questionId: string, observe?: 'body', reportProgress?: boolean): Observable<Question>;
    public getQuestion(apiVersion: string, questionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Question>>;
    public getQuestion(apiVersion: string, questionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Question>>;
    public getQuestion(apiVersion: string, questionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling getQuestion.');
        }

        if (questionId === null || questionId === undefined) {
            throw new Error('Required parameter questionId was null or undefined when calling getQuestion.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Question>(`${this.basePath}/${encodeURIComponent(String(apiVersion))}/editor/question/${encodeURIComponent(String(questionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get difficulties for all the published questions
     * 
     * @param apiVersion API version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getQuestionDifficulties(apiVersion: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Difficulty>>;
    public getQuestionDifficulties(apiVersion: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Difficulty>>>;
    public getQuestionDifficulties(apiVersion: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Difficulty>>>;
    public getQuestionDifficulties(apiVersion: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling getQuestionDifficulties.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Difficulty>>(`${this.basePath}/${encodeURIComponent(String(apiVersion))}/player/question/difficulties`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all questions for particular application
     * 
     * @param apiVersion API version
     * @param offset Record to start
     * @param limit Number of records to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getQuestions(apiVersion: string, offset?: number, limit?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<Question>>;
    public getQuestions(apiVersion: string, offset?: number, limit?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Question>>>;
    public getQuestions(apiVersion: string, offset?: number, limit?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Question>>>;
    public getQuestions(apiVersion: string, offset?: number, limit?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling getQuestions.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Question>>(`${this.basePath}/${encodeURIComponent(String(apiVersion))}/editor/question/`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all questions number
     * 
     * @param apiVersion API version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getQuestionsCount(apiVersion: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public getQuestionsCount(apiVersion: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public getQuestionsCount(apiVersion: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public getQuestionsCount(apiVersion: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling getQuestionsCount.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<string>(`${this.basePath}/${encodeURIComponent(String(apiVersion))}/editor/question/count`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get questions to preview
     * 
     * @param apiVersion API version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getQuestionsToPreview(apiVersion: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Question>>;
    public getQuestionsToPreview(apiVersion: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Question>>>;
    public getQuestionsToPreview(apiVersion: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Question>>>;
    public getQuestionsToPreview(apiVersion: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling getQuestionsToPreview.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Question>>(`${this.basePath}/${encodeURIComponent(String(apiVersion))}/editor/question/preview`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Mark question to be previewed
     * 
     * @param apiVersion API version
     * @param question Question to be previewed
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public markForPreview(apiVersion: string, question: Question, observe?: 'body', reportProgress?: boolean): Observable<Array<Question>>;
    public markForPreview(apiVersion: string, question: Question, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Question>>>;
    public markForPreview(apiVersion: string, question: Question, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Question>>>;
    public markForPreview(apiVersion: string, question: Question, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling markForPreview.');
        }

        if (question === null || question === undefined) {
            throw new Error('Required parameter question was null or undefined when calling markForPreview.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Array<Question>>(`${this.basePath}/${encodeURIComponent(String(apiVersion))}/editor/question/preview`,
            question,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Publishes new version, create a snapshot
     * 
     * @param apiVersion API version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public publishNewVersion(apiVersion: string, observe?: 'body', reportProgress?: boolean): Observable<PublishStatistics>;
    public publishNewVersion(apiVersion: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PublishStatistics>>;
    public publishNewVersion(apiVersion: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PublishStatistics>>;
    public publishNewVersion(apiVersion: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling publishNewVersion.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/octet-stream'
        ];

        return this.httpClient.post<PublishStatistics>(`${this.basePath}/${encodeURIComponent(String(apiVersion))}/editor/question/publish`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update an existing question
     * 
     * @param apiVersion API version
     * @param question Question to be modified
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateQuestion(apiVersion: string, question: Question, observe?: 'body', reportProgress?: boolean): Observable<Question>;
    public updateQuestion(apiVersion: string, question: Question, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Question>>;
    public updateQuestion(apiVersion: string, question: Question, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Question>>;
    public updateQuestion(apiVersion: string, question: Question, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling updateQuestion.');
        }

        if (question === null || question === undefined) {
            throw new Error('Required parameter question was null or undefined when calling updateQuestion.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Question>(`${this.basePath}/${encodeURIComponent(String(apiVersion))}/editor/question/`,
            question,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
