import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { switchMap, tap } from 'rxjs/operators';
import { Question } from '../client';
import { QuestionService } from '../client/api/question.service';
import { API_VERSION } from '../auth/version';
import { of } from 'rxjs';

@Injectable()
export class QuestionManager {
    private questions: Question[];

    constructor(private questionService: QuestionService) { }
    /**
     * Adds human-readable fake IDs, used on front-end only
     */
    private applyFakeIds(questions: Question[]): Question[] {
        for (let i = 0; i < questions.length; i++) {
            questions[i]['num'] = i + 1;
        }
        return questions;
    }

    public getQuestions(): Question[] {
        return this.questions;
    }

    public getQuestionById(questionId: string): Question {
        return this.questions.find(question => question._id === questionId);
    }

    public fetchQuestions(): Observable<Question[]> {
        if (!this.questions) {
            return this.questionService.getQuestions(API_VERSION)
                .pipe(
                    switchMap(questions => {
                        return of(this.applyFakeIds(questions));
                    }),
                    tap(data => {
                        // Save in cache
                        this.questions = data;
                    })
                );
        }
        return of(this.questions);
    }

    public publishNewVersion() {
        return this.questionService.publishNewVersion(API_VERSION);
    }

    public markForPreview(question: Question) {
        return this.questionService.markForPreview(API_VERSION, question);
    }

    public addQuestion(question: Question): Observable<Question> {
        return this.questionService.addQuestion(API_VERSION, question)
            .pipe(
                switchMap(newItem => {
                    return this.questionService.getQuestion(API_VERSION, newItem._id);
                }),
                tap(newItem => {
                    this.questions.push(newItem);
                    this.questions = this.applyFakeIds(this.questions);
                })
            );
    }

    public updateQuestion(question: Question): Observable<Question> {
        return this.questionService.updateQuestion(API_VERSION, question)
            .pipe(
                switchMap(newItem => {
                    return this.questionService.getQuestion(API_VERSION, newItem._id);
                }),
                tap(newItem => {
                    const indexToUpdate = this.questions.findIndex(item => item._id === newItem._id);
                    if (indexToUpdate > -1) {
                        this.questions[indexToUpdate] = newItem;
                    }
                    this.questions = this.applyFakeIds(this.questions);
                })
            );
    }

    public deleteQuestion(questionId: string) {
        return this.questionService.deleteQuestion(API_VERSION, questionId)
            .pipe(
                tap(_ => {
                    const indexToDelete = this.questions.findIndex(item => item._id === questionId);
                    if (indexToDelete > -1) {
                        this.questions.splice(indexToDelete, 1);
                    }
                    this.questions = this.applyFakeIds(this.questions);
                })
            );
    }
}
