import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Message } from 'primeng/api';
import { AuthService } from '../../client/index';
import { TokenService } from '../token.service';
import { API_VERSION } from '../version';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private tokenService: TokenService,
    private router: Router,
    private ref: ChangeDetectorRef
  ) { }

  public email: string;
  public password: string;
  public msgs: Message[];

  ngOnInit() {
    this.attachEnterKey('emailText');
    this.attachEnterKey('passwordText');
  }

  private attachEnterKey(inputId) {
    document
      .querySelector('#' + inputId)
      .addEventListener('keyup', (event: any) => {
        if (event.key !== 'Enter') {
          return;
        }
        (document.querySelector('#loginButton') as any).click();
        event.preventDefault();
      });
  }

  public login() {
    this.authService
      .authenticate(
        API_VERSION,
        {
          email: this.email,
          password: this.password
        })
      .subscribe(
        result => {
          this.msgs = null;
          this.tokenService.setToken(result.token);
          this.router.navigate(['']);

          // Change detection needed to force parent component to update itself
          this.ref.detectChanges();
        },
        error => {
          const msg: Message = {
            severity: 'warn',
            summary: 'Failed',
            detail: 'Invalid credentials'
          };
          this.msgs = [msg];
        }
      );
  }
}
