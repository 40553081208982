<ng-container>
  <p-table #dt [globalFilterFields]="['num','content','status','tags','_id']" [columns]="cols" [value]="questionsShown"
    [paginator]="true" [rows]="15" (onRowSelect)="showDialogToEdit($event)" selectionMode="single" sortField="num"
    sortOrder="1" (sortFunction)="customSort($event)" [customSort]="true" [first]="firstIndex" (onPage)="onPage($event)"
    responsiveLayout="scroll">
    <ng-template pTemplate="caption">
      <div class="grid">
        <div class="col-6 lg:col-6">
          <input type="text" class="w-full" pInputText placeholder="Filter by question, number, id, status and tags"
            [(ngModel)]="filterText" (input)="onFilter($event.target.value)">
        </div>
        <div class="col-6 lg:col-offset-4 lg:col-2">
          <button type="button" pButton icon="pi pi-plus"
            class="border-white w-full text-overflow-ellipsis white-space-nowrap" (click)="showDialogToAdd()"
            label="New question"></button>
        </div>
      </div>
    </ng-template>

    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns" [pSortableColumn]="col.field" [style.width]="col.width"
          [class.hideMobile]="col.hideMobile" [class.hideTablet]="col.hideTablet">
          {{col.header}}
          <p-sortIcon [field]="col.field"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr [pSelectableRow]="rowData">
        <td *ngFor="let col of columns" [style.textAlign]="col.align" [class.hideMobile]="col.hideMobile"
          [class.hideTablet]="col.hideTablet">
          <div class='ellipsis'>{{ getCellValue(questions, rowData, col.field) }}</div>
        </td>
      </tr>
    </ng-template>
  </p-table>

  <div class="py-2">
    <button type="button" pButton icon="pi pi-globe" class="col-6 lg:col-2 text-overflow-ellipsis white-space-nowrap"
      (click)="publishVersion()" label="Publish version"></button>
    <button type="button" pButton icon="pi pi-plus"
      class="col-6 lg:col-offset-8 lg:col-2 text-overflow-ellipsis white-space-nowrap" (click)="showDialogToAdd()"
      label="New question"></button>
  </div>
</ng-container>