import { HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { TokenService } from '../auth/token.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private tokenService: TokenService, private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // Get the auth token from the service.
    const authToken = this.tokenService.getTokenHeaderValue();

    // Clone the request and replace the original headers with
    // cloned headers, updated with the authorization.
    const authReq = req.clone({
      headers: req.headers.set('Authorization', authToken)
    });

    // send cloned request with header to the next handler.
    return next.handle(authReq)
      .pipe(
        tap(
          // Succeeds when there is a response; ignore other events
          event => {
          },
          // Operation failed; error is an HttpErrorResponse
          error => {
            const httpErr: HttpResponse<any> = error as HttpResponse<any>;
            if (httpErr.status === 401) {
              this.tokenService.removeToken();
              this.router.navigate(['login']);
            }
          }
        )
      );
  }
}
