export * from './auth.service';
import { AuthService } from './auth.service';
export * from './contact.service';
import { ContactService } from './contact.service';
export * from './dashboard.service';
import { DashboardService } from './dashboard.service';
export * from './editor.service';
import { EditorService } from './editor.service';
export * from './player.service';
import { PlayerService } from './player.service';
export * from './question.service';
import { QuestionService } from './question.service';
export const APIS = [AuthService, ContactService, DashboardService, EditorService, PlayerService, QuestionService];
