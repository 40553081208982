import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfirmationService, MessageService, SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { RadioButtonModule } from 'primeng/radiobutton';
import { MenuModule } from 'primeng/menu';
import { CheckboxModule } from 'primeng/checkbox';
import { SpinnerModule } from 'primeng/spinner';
import { ToastModule } from 'primeng/toast';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { SelectButtonModule } from 'primeng/selectbutton';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MessagesModule } from 'primeng/messages';
import { DialogModule } from 'primeng/dialog';
import { PanelModule } from 'primeng/panel';
import { PasswordModule } from 'primeng/password';
import { CardModule } from 'primeng/card';
import { ChartModule } from 'primeng/chart';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MessageModule } from 'primeng/message';
import { TableModule } from 'primeng/table';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CheckAuthGuard } from './auth/check-auth.guard';
import { CheckUnAuthGuard } from './auth/check-unauth.guard';
import { LoginComponent } from './auth/login/login.component';
import { TokenService } from './auth/token.service';
import { ApiModule, Configuration, ConfigurationParameters, EditorService, QuestionService } from './client/index';
import { DashboardComponent } from './dashboard/dashboard.component';
import { EditorsComponent } from './editors/editors.component';
import { AuthInterceptor } from './interceptors/auth-interceptor';
import { NotFoundComponent } from './not-found/not-found.component';
import { PlayersComponent } from './players/players.component';
import { QuestionComponent } from './question/question.component';
import { QuestionsComponent } from './questions/questions.component';
import { AppConfigService } from './services/app.config.service';
import { LogService } from './services/log.service';
import { NavigationService } from './services/navigation.service';
import { QuestionManager } from './services/question.manager';
import { RouterExtService } from './services/router-ext.service';
import { InputNumberModule } from 'primeng/inputnumber';

export interface IAppConfig {
  appId: string;
  apiUrl: string;
}

export let tempAppConfig: IAppConfig;

@NgModule({
  declarations: [
    AppComponent,
    QuestionsComponent,
    LoginComponent,
    DashboardComponent,
    EditorsComponent,
    NotFoundComponent,
    PlayersComponent,
    QuestionComponent,
  ],
  imports: [
    FormsModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ButtonModule,
    PasswordModule,
    InputTextModule,
    InputTextareaModule,
    MessagesModule,
    MessageModule,
    TableModule,
    InputNumberModule,
    SharedModule,
    MenuModule,
    PanelModule,
    DialogModule,
    RadioButtonModule,
    CheckboxModule,
    SpinnerModule,
    ConfirmDialogModule,
    ToastModule,
    AutoCompleteModule,
    ChartModule,
    CardModule,
    SelectButtonModule,
    ApiModule.forRoot(getConfiguration)
  ],
  providers: [
    CheckAuthGuard,
    CheckUnAuthGuard,
    TokenService,
    ConfirmationService,
    MessageService,
    LogService,
    NavigationService,
    QuestionService,
    QuestionManager,
    EditorService,
    AppConfigService,
    RouterExtService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [AppConfigService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function getConfiguration(): Configuration {
  const configParams: ConfigurationParameters = {
    apiKeys: {},
    username: null,
    password: null,
    accessToken: null,
    basePath: `${document.location.protocol}//${tempAppConfig.apiUrl ? tempAppConfig.apiUrl : 'localhost:8080'}`,
    withCredentials: false,
  };

  return new Configuration(configParams);
}

export function appInitializerFn(appConfigService: AppConfigService): () => Promise<any> {
  return () => {
    return appConfigService.loadAppConfig()
      .then(() => {
        tempAppConfig = appConfigService.getConfig();
      });
  };
}
