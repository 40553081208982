import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { EditorService, Player } from '../client';
import { Utils } from '../questions/utils';
import { API_VERSION } from '../auth/version';

@Component({
  selector: 'app-players',
  templateUrl: './players.component.html',
  styleUrls: ['./players.component.scss']
})
export class PlayersComponent implements OnInit {
  public players$: Observable<Player[]>;
  public cols: any[];

  constructor(
    private editorsService: EditorService
  ) { }

  ngOnInit() {
    this.players$ = this.editorsService.getPlayers(API_VERSION);

    this.cols = [
      { field: 'name', header: 'Name', align: 'left' },
      { field: 'points_total', header: 'Total', width: '100px' },
      { field: 'points_weekly', header: 'Week', width: '110px' },
      { field: 'difficulty', header: 'Difficulty', width: '136px', hideMobile: true },
      { field: 'email', header: 'Email', width: '280px', align: 'left', hideMobile: true, hideTablet: true },
      { field: 'createdAt', header: 'Registered', width: '160px', align: 'right', hideMobile: true, hideTablet: true }
    ];
  }

  getCellValue(player: any, fieldName: string): string {
    let formatted: string;
    switch (fieldName) {
      case 'name':
        formatted = this.formatName(player.name);
        break;
      case 'createdAt':
        formatted = this.formatDate(player.createdAt);
        break;
      case 'difficulty':
        formatted = this.formatDifficulty(player.difficulty);
        break;
      default:
        formatted = player[fieldName];
        break;
    }
    return formatted;
  }

  public formatDate(date: string) {
    if (!date) {
      return '';
    }
    return Utils.formatTime(new Date(date));
  }

  public formatName(name: string) {
    return name ? name : '-';
  }

  public formatDifficulty(diff: number) {
    return diff.toFixed(2);
  }
}
