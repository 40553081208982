<p-panel [header]="'Overview'">
  <div class="grid">

    <p-card class="lg:col-6 md:col-12 sm:col-12" header="Registrations">
      <p-selectButton [options]="statTypes" [(ngModel)]="currentRegStatType"
        (onChange)="loadRegistrations(currentRegStatType)"></p-selectButton>
      <p-chart *ngIf=" currentRegStatType==='daily'" type="bar" height="32vh" [options]="regChartOptions"
        [data]="dailyRegStat">
      </p-chart>
      <p-chart *ngIf="currentRegStatType === 'monthly'" type="bar" height="32vh" [options]="regChartOptions"
        [data]="monthlyRegStat">
      </p-chart>
      <p-chart *ngIf="currentRegStatType === 'yearly'" type="bar" height="32vh" [options]="regChartOptions"
        [data]="yearlyRegStat">
      </p-chart>
    </p-card>

    <p-card class="lg:col-6 md:col-12 sm:col-12" header="Questions answered">
      <p-selectButton [options]="statTypes" [(ngModel)]="currentQuestionStatType"
        (onChange)="loadQuestionsAnswered(currentQuestionStatType)"></p-selectButton>
      <p-chart *ngIf="currentQuestionStatType === 'daily'" type="bar" height="32vh" [options]="questionChartOptions"
        [data]="dailyQuestionStat"></p-chart>
      <p-chart *ngIf="currentQuestionStatType === 'monthly'" type="bar" height="32vh" [options]="questionChartOptions"
        [data]="monthlyQuestionStat">
      </p-chart>
      <p-chart *ngIf="currentQuestionStatType === 'yearly'" type="bar" height="32vh" [options]="questionChartOptions"
        [data]="yearlyQuestionStat">
      </p-chart>
    </p-card>

    <p-card class="lg:col-6 md:col-12 sm:col-12" header="Active players">
      <p-selectButton [options]="statTypes" [(ngModel)]="currentPlayersStatType"
        (onChange)="loadActivePlayers(currentPlayersStatType)"></p-selectButton>
      <p-chart *ngIf="currentPlayersStatType === 'daily'" type="bar" height="32vh" [options]="playersChartOptions"
        [data]="dailyPlayersStat">
      </p-chart>
      <p-chart *ngIf="currentPlayersStatType === 'monthly'" type="bar" height="32vh" [options]="playersChartOptions"
        [data]="monthlyPlayersStat">
      </p-chart>
      <p-chart *ngIf="currentPlayersStatType === 'yearly'" type="bar" height="32vh" [options]="playersChartOptions"
        [data]="yearlyPlayersStat">
      </p-chart>
    </p-card>

    <p-card class="lg:col-6 md:col-12 sm:col-12" header="Questions popularity">
      <p-selectButton [options]="singleStatType"></p-selectButton>
      <p-chart *ngIf="popularityStat" type="bar" height="32vh" [data]="popularityStat"></p-chart>
    </p-card>

    <p-card class="lg:col-6 md:col-6 sm:col-12" header="Question difficulties">
      <p-chart type="pie" [data]="difficultyStat" height="30vh"></p-chart>
    </p-card>

    <p-card class="lg:col-6 md:col-6 sm:col-12" header="Question tags">
      <p-chart type="pie" [data]="tagStat" height="30vh"></p-chart>
    </p-card>

  </div>

</p-panel>