/**
 * Jannah Quiz API
 * JannahQuiz API server
 *
 * OpenAPI spec version: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { Email } from '../model/email';
import { EmailCode } from '../model/emailCode';
import { HighScores } from '../model/highScores';
import { Player } from '../model/player';
import { PlayerAnswer } from '../model/playerAnswer';
import { PlayerData } from '../model/playerData';
import { Token } from '../model/token';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class PlayerService {

    protected basePath = 'http://localhost:8080';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Finish round and recalculate points
     * 
     * @param apiVersion API version
     * @param playerAnswers Player answers for the round
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public finishRound(apiVersion: string, playerAnswers: Array<PlayerAnswer>, observe?: 'body', reportProgress?: boolean): Observable<Player>;
    public finishRound(apiVersion: string, playerAnswers: Array<PlayerAnswer>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Player>>;
    public finishRound(apiVersion: string, playerAnswers: Array<PlayerAnswer>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Player>>;
    public finishRound(apiVersion: string, playerAnswers: Array<PlayerAnswer>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling finishRound.');
        }

        if (playerAnswers === null || playerAnswers === undefined) {
            throw new Error('Required parameter playerAnswers was null or undefined when calling finishRound.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Player>(`${this.basePath}/${encodeURIComponent(String(apiVersion))}/player/round/finish`,
            playerAnswers,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get configuration
     * 
     * @param apiVersion API version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getConfiguration(apiVersion: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public getConfiguration(apiVersion: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public getConfiguration(apiVersion: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public getConfiguration(apiVersion: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling getConfiguration.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/${encodeURIComponent(String(apiVersion))}/player/configuration`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get highscores
     * 
     * @param apiVersion API version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getHighScores(apiVersion: string, observe?: 'body', reportProgress?: boolean): Observable<HighScores>;
    public getHighScores(apiVersion: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<HighScores>>;
    public getHighScores(apiVersion: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<HighScores>>;
    public getHighScores(apiVersion: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling getHighScores.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<HighScores>(`${this.basePath}/${encodeURIComponent(String(apiVersion))}/player/highscores`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get player data
     * 
     * @param apiVersion API version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPlayerData(apiVersion: string, observe?: 'body', reportProgress?: boolean): Observable<PlayerData>;
    public getPlayerData(apiVersion: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PlayerData>>;
    public getPlayerData(apiVersion: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PlayerData>>;
    public getPlayerData(apiVersion: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling getPlayerData.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PlayerData>(`${this.basePath}/${encodeURIComponent(String(apiVersion))}/player/data`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Authenticate as anonymous
     * 
     * @param apiVersion API version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public loginAnonymous(apiVersion: string, observe?: 'body', reportProgress?: boolean): Observable<Token>;
    public loginAnonymous(apiVersion: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Token>>;
    public loginAnonymous(apiVersion: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Token>>;
    public loginAnonymous(apiVersion: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling loginAnonymous.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Token>(`${this.basePath}/${encodeURIComponent(String(apiVersion))}/player/auth/login_anonymous`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Authenticate via email and code
     * 
     * @param apiVersion API version
     * @param credentials Email and code
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public loginByCode(apiVersion: string, credentials: EmailCode, observe?: 'body', reportProgress?: boolean): Observable<Token>;
    public loginByCode(apiVersion: string, credentials: EmailCode, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Token>>;
    public loginByCode(apiVersion: string, credentials: EmailCode, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Token>>;
    public loginByCode(apiVersion: string, credentials: EmailCode, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling loginByCode.');
        }

        if (credentials === null || credentials === undefined) {
            throw new Error('Required parameter credentials was null or undefined when calling loginByCode.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Token>(`${this.basePath}/${encodeURIComponent(String(apiVersion))}/player/auth/login`,
            credentials,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get authentication code
     * 
     * @param apiVersion API version
     * @param email Email for code generation
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public requestCode(apiVersion: string, email: Email, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public requestCode(apiVersion: string, email: Email, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public requestCode(apiVersion: string, email: Email, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public requestCode(apiVersion: string, email: Email, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling requestCode.');
        }

        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling requestCode.');
        }

        let headers = this.defaultHeaders;

        // authentication (BearerOptional) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/${encodeURIComponent(String(apiVersion))}/player/auth/code`,
            email,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set player difficulty
     * 
     * @param apiVersion API version
     * @param playerData Player data to set
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setPlayerDifficulty(apiVersion: string, playerData: PlayerData, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public setPlayerDifficulty(apiVersion: string, playerData: PlayerData, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public setPlayerDifficulty(apiVersion: string, playerData: PlayerData, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public setPlayerDifficulty(apiVersion: string, playerData: PlayerData, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling setPlayerDifficulty.');
        }

        if (playerData === null || playerData === undefined) {
            throw new Error('Required parameter playerData was null or undefined when calling setPlayerDifficulty.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/${encodeURIComponent(String(apiVersion))}/player/data/difficulty`,
            playerData,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set player email
     * 
     * @param playerData Player email to set
     * @param apiVersion API version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setPlayerEmail(playerData: PlayerData, apiVersion: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public setPlayerEmail(playerData: PlayerData, apiVersion: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public setPlayerEmail(playerData: PlayerData, apiVersion: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public setPlayerEmail(playerData: PlayerData, apiVersion: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (playerData === null || playerData === undefined) {
            throw new Error('Required parameter playerData was null or undefined when calling setPlayerEmail.');
        }

        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling setPlayerEmail.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/${encodeURIComponent(String(apiVersion))}/player/data/email`,
            playerData,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Set player name
     * 
     * @param playerData Player data to set
     * @param apiVersion API version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setPlayerName(playerData: PlayerData, apiVersion: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public setPlayerName(playerData: PlayerData, apiVersion: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public setPlayerName(playerData: PlayerData, apiVersion: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public setPlayerName(playerData: PlayerData, apiVersion: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (playerData === null || playerData === undefined) {
            throw new Error('Required parameter playerData was null or undefined when calling setPlayerName.');
        }

        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling setPlayerName.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/${encodeURIComponent(String(apiVersion))}/player/data/name`,
            playerData,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
