/**
 * Jannah Quiz API
 * JannahQuiz API server
 *
 * OpenAPI spec version: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface QuestionFeedback { 
    questionId: string;
    reason?: string;
    description?: string;
}
