import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CheckAuthGuard } from './auth/check-auth.guard';
import { CheckUnAuthGuard } from './auth/check-unauth.guard';
import { LoginComponent } from './auth/login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { EditorsComponent } from './editors/editors.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { PlayersComponent } from './players/players.component';
import { QuestionComponent } from './question/question.component';
import { QuestionsComponent } from './questions/questions.component';

const routes: Routes = [
  { path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full' },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [CheckAuthGuard],
  },
  {
    path: 'questions/:page',
    component: QuestionsComponent,
    canActivate: [CheckAuthGuard],
  },
  {
    path: 'questions',
    component: QuestionsComponent,
    canActivate: [CheckAuthGuard],
  },
  {
    path: 'question/:id',
    component: QuestionComponent,
    canActivate: [CheckAuthGuard]
  },
  {
    path: 'editors',
    component: EditorsComponent,
    canActivate: [CheckAuthGuard]
  },
  {
    path: 'players',
    component: PlayersComponent,
    canActivate: [CheckAuthGuard]
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [CheckUnAuthGuard]
  },
  {
    path: '**',
    component: NotFoundComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
